import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { boot } from 'quasar/wrappers'
import BootMixins from 'src/mixins/boot'
import GlobalMixins from 'hc-core/mixins/global.js'
import { intlInit } from 'hc-core/composables/intl'
import HCButton from 'hc-core/components/buttons/hc-button'
import HCBigButton from 'hc-core/components/buttons/hc-big-button'
import HCLoading from 'hc-core/components/common/hc-loading'
import AppAvatar from 'hc-core/components/common/app-avatar'
import AppContent from 'hc-core/components/common/app-content'
import AccountCard from 'hc-core/components/cards/account-card'
import RawSnippet from 'hc-core/components/snippets/raw-snippet'
import ActionTooltip from 'hc-core/components/tooltips/action-tooltip'

// Refactored common > ui components
import { Chip } from 'hc-core/components/ui/chip'
import { Checkbox } from 'hc-core/components/ui/checkbox'

export default boot(async ({ app, store }) => {
  // Components
  app.component('Chip', Chip)
  app.component('Checkbox', Checkbox)

  app.component('HCButton', HCButton)
  app.component('HCLoading', HCLoading)
  app.component('AppAvatar', AppAvatar)
  app.component('AppContent', AppContent)
  app.component('RawSnippet', RawSnippet)
  app.component('AccountCard', AccountCard)
  app.component('HCBigButton', HCBigButton)
  app.component('ActionTooltip', ActionTooltip)

  // Mixins
  app.mixin(BootMixins)
  app.mixin(GlobalMixins)

  // Prototypes
  app.config.globalProperties.$_ = _
  app.config.globalProperties.$m = moment
  app.config.globalProperties.$axios = axios.create()

  moment.updateLocale('fr', {
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    relativeTime: {
      future: 'in %s',
      past: 'il y a %s',
      s: 'quelques secondes',
      ss: '%d secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      w: 'une semaine',
      ww: '%d semaines',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans'
    }
  })

  // Intl setup
  await intlInit({ app, store })
})
